
.dropbox {
  outline: 1px dashed #ffffff; /* the dash box */
  outline-offset: -10px;
  background: #1ab394;
  position: relative;
  cursor: pointer;
  color: #ffffff;
}
.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
}
.dropbox:hover {
  background: #18a689; /* when mouse over to the drop zone, change color */
}
.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 20px 0;
}
table.files-to-upload>tbody>tr>td {
  line-height: 33px;
}
table.files-to-upload .options a {
  font-size: 1.3em;
  margin-right: 10px;
}


td div.pretty[data-v-d5828e40] {
  margin:0;
}

